import React, { useRef, useEffect } from "react"
import styles from "./index.module.scss"
import Img from "gatsby-image"
import { initVimeoPlayer, initVimeoPlayerWithControl } from "../../utils"
import CTABtn from "../../Button/CTA"
import BackgroundImage from "gatsby-background-image"
import Explore from "../Home/Explore"
import Join from "../Home/Join"
import loadStates from "../../../hocs/loadStates"
import Money from "../../../images/ArticleTwo/money-icon.png"
import Loan from "../../../images/ArticleTwo/loan-icon.png"
import List from "../../../images/ArticleTwo/list-icon.png"
import One from "../../../images/ArticleTwo/one-icon.png"
import Two from "../../../images/ArticleTwo/two-icon.png"
import Three from "../../../images/ArticleTwo/three-icon.png"

function ArticleTwo({ sanity, lang: { locale } }) {
  const isChinese = locale === "tc"
  return (
    <div>
      <Header data={sanity} isChinese={isChinese} />
      <SectionOne data={sanity} isChinese={isChinese} />
      <SectionTwo data={sanity} isChinese={isChinese} />
      <SectionThree data={sanity} isChinese={isChinese} />
      <SectionFour data={sanity} isChinese={isChinese} />
      <SectionFive data={sanity} isChinese={isChinese} />
      <SectionSix />
      <SectionSeven data={sanity} isChinese={isChinese} />
      <SectionEight data={sanity} />
      <SectionNine data={sanity} isChinese={isChinese} />
      <SectionTen data={sanity} />
      <SectionEleven data={sanity} isChinese={isChinese} />
      <SectionTwelve data={sanity} isChinese={isChinese} />
      <Explore home={sanity.sanityHomePage} />
      <Join home={sanity.sanityHomePage} />
      <CTABtn />
    </div>
  )
}

export default loadStates(ArticleTwo)

function Header({ data, isChinese }) {
  const video = useRef(null)

  useEffect(() => {
    video.current &&
      initVimeoPlayerWithControl({
        selector: video.current,
        desktopVid: "https://vimeo.com/440515860/25462d89c2",
        mobileVid: null,
      })
  }, [])

  return (
    <section>
      <div ref={video}></div>
      {/* <header
          className={`${styles.header} relative overflow-hidden flex justify-start`}
        >
          <div className="bg-container absolute w-full h-full top-0 left-0 -z-10">
            <div
              ref={video}
              className="video-iframe-container absolute top-1/2 left-1/2 w-full h-full transform -translate-x-1/2 -translate-y-1/2"
            ></div>
          </div>
        </header> */}
    </section>
  )
}

function SectionOne({ data, isChinese }) {
  return (
    <section className="py-40 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <h2 className="quotes md:text-6xl bold-font mb-20 md:mb-10">
              90-Second with BMW Professional Vol. 1: BMW Finance
            </h2>
            {isChinese ? (
              <p className="large-text">
                一系列90-second with BMW
                Professional由BMW專業人員以不一樣的角度向您講解一系列品牌及產品冷知道，為您提供更全面及最新資訊。本集以財務為主題，讓您透過90秒時間，加深對汽車供款方式的了解。
              </p>
            ) : (
              <p className="large-text">
                The series of 90-second with BMW Professional connects you with
                our BMW experts and ensure that you receive the most
                comprehensive BMW tips and experience. This episode focuses on
                finance, allowing you to deepen your understanding of Financing
                option in 90 seconds.
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionTwo({ data, isChinese }) {
  return (
    <section>
      <div className="image-container">
        <Img fluid={data.articleimg2.childImageSharp.fluid} />
      </div>
    </section>
  )
}

function SectionThree({ data, isChinese }) {
  return (
    <section className="py-40 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="flex">
              <div className="w-1/2 md:w-full">
                <h3 className="text-5xl md:text-4xl bold-font mb-32 md:mb-10">
                  {isChinese
                    ? "除了最常見的租購 (Hire Purchase)同租賃 (Leasing) 供款方式外，BMW Circle帶給車主更大靈活性。"
                    : "Except for the commonly-known options like Hire Purchase and Leasing, BMW Circle can bring you more flexibility."}
                </h3>
              </div>
            </div>
            <div className="flex md:flex-col">
              <div className="w-1/2 md:w-full pr-20 md:pr-0">
                <div className="flex justify-end">
                  <hr className="w-64 md:w-full h-1 bg-black mb-32 md:hidden" />
                </div>
                <div className="flex justify-end">
                  <div className="md:w-full mb-32 text-right md:text-center md:mt-20 md:mb-10">
                    <div className="headline md:text-4xl">
                      {isChinese ? (
                        <>
                          <div className="flex md:flex-col">
                            <div
                              className={`icon-container w-20 mr-6 md:mr-0 md:mb-10 ${styles.iconContainer} self-center`}
                            >
                              <img src={Money} alt="" className="w-full" />
                            </div>
                            <span className="self-center whitespace-no-wrap">
                              每月供款額大約
                            </span>
                          </div>
                          <div className="md:text-center">HK$46,000</div>
                        </>
                      ) : (
                        <>
                          <div className="flex md:flex-col">
                            <div
                              className={`icon-container w-20 md:w-12 mr-6 ${styles.iconContainer} self-center`}
                            >
                              <img src={Money} alt="" className="w-full" />
                            </div>
                            <span className="self-center whitespace-no-wrap">
                              The monthly payment is
                            </span>
                          </div>
                          <span>around $46,000.</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 md:w-full pl-20 md:pl-0 md:mt-10">
                <p className="large-text">
                  {isChinese
                    ? "以一間想購入一部豪華房車BMW 7 Series當公司車的公司為例，如果選擇24個月年期以及10%首期的租購"
                    : "For example, a company wants to purchase The BMW 7 Series as a company car. If they choose Hire Purchase with a period of 24 months and 10% Down Payment"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionFour({ data, isChinese }) {
  return (
    <section>
      <div className={`bg-container w-full ${styles.banner}`}>
        <BackgroundImage
          Tag="div"
          className="w-full h-full"
          fluid={data.articleimg3.childImageSharp.fluid}
          backgroundColor={`#040e18`}
          loading="eager"
          critical={true}
        />
      </div>
    </section>
  )
}

function SectionFive({ data, isChinese }) {
  return (
    <section className="py-40 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="flex">
              <div className="w-1/2 md:w-full">
                <h3 className="text-5xl md:text-4xl bold-font mb-32 md:mb-10">
                  {isChinese
                    ? "若果選擇BMW Circle，年期同樣為24個月和10%首期:"
                    : "On the other hand, if they choose BMW Circle under the same period of 24 months and 10% Down Payment"}
                </h3>
              </div>
            </div>
            <div className="flex md:flex-col">
              <div className="w-1/2 md:w-full pr-20 md:pr-0">
                <div className="flex justify-end">
                  <hr className="w-64 md:w-full h-1 bg-black mb-32 md:hidden" />
                </div>
                <div className="flex justify-end">
                  <div className="md:w-full mb-32 text-right md:text-center md:mt-20 md:mb-10">
                    <div className="headline md:text-4xl">
                      {isChinese ? (
                        <>
                          <div className="flex md:flex-col">
                            <div
                              className={`icon-container w-20 mr-6 md:mr-0 md:mb-10 ${styles.iconContainer} self-center`}
                            >
                              <img src={Money} alt="" className="w-full" />
                            </div>
                            <h4 className="self-center whitespace-no-wrap">
                              每月供款額大約
                            </h4>
                          </div>
                          <h4 className="md:text-center">HK$26,000</h4>
                        </>
                      ) : (
                        <>
                          <div className="flex md:flex-col">
                            <div
                              className={`icon-container w-20 mr-6 md:mr-0 md:mb-10 ${styles.iconContainer} self-center`}
                            >
                              <img src={Money} alt="" className="w-full" />
                            </div>
                            <h4 className="self-center whitespace-no-wrap">
                              The monthly payment is
                            </h4>
                          </div>
                          <h4 className="md:text-center">around $26,000.</h4>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 md:w-full pl-20 md:pl-0 md:mt-16">
                <p className="large-text mb-32 md:mb-16">
                  {isChinese
                    ? "當中月供相差接近2萬元。"
                    : "This results in an almost $20,000 difference."}
                </p>
                <p className="large-text light-font">
                  {isChinese
                    ? "BMW Circle低月供令公司能夠自由運用其餘資金作其它業務之用，甚至調配資金去聘請司機等等。"
                    : "The low installments of BMW Circle give company the freedom to spend the money otherwise and may even allocate the resources to other uses such as hiring a driver."}
                </p>
                <div className="btn-container mt-16">
                  <a
                    href={
                      isChinese
                        ? "https://connect.bmwhk.com/tc/?utm_medium=website&utm_source=awb_site&utm_campaign=AlongWithBMW2020&utm_content=standard&lang=tc"
                        : "https://connect.bmwhk.com/en/?utm_medium=website&utm_source=awb_site&utm_campaign=AlongWithBMW2020&utm_content=standard&lang=en"
                    }
                    target="_blank"
                    className="px-8 py-4 border border-solid border-black inline-block hover:bg-black hover:text-white duration-300 transition-all ease-out"
                  >
                    {isChinese
                      ? "了解更多有關車款優惠"
                      : "Learn More About Car offers"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionSix() {
  const video = useRef(null)

  useEffect(() => {
    video.current &&
      initVimeoPlayer({
        selector: video.current,
        desktopVid: "https://vimeo.com/439977582/3010290d0a",
        mobileVid: null,
      })
  }, [])

  return (
    <section>
      <div ref={video}></div>
    </section>
  )
}

function SectionSeven({ data, isChinese }) {
  return (
    <section className="py-40 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="flex">
              <div className="w-1/2 md:w-full">
                <h3 className="text-5xl md:text-4xl bold-font mb-32 md:mb-10">
                  {isChinese
                    ? "另外，以一個準備迎接小生命來臨的家庭為例，他們希望選購一部既安全又舒適的車輛方便出入。"
                    : "Let’s look at another scenario. A family with a baby on the way is looking to purchase a comfortable and safe vehicle."}
                </h3>
              </div>
            </div>
            <div className="flex md:flex-col">
              <div className="w-1/2 md:w-full pr-20 md:pr-0">
                <div className="flex justify-end">
                  <hr className="w-64 md:w-full h-1 bg-black mb-32 md:hidden" />
                </div>
                <div className="flex justify-end">
                  <div className="md:w-full mb-32 text-right md:text-center md:mt-20 md:mb-10">
                    <div className="headline md:text-4xl">
                      {isChinese ? (
                        <>
                          <div className="flex md:flex-col">
                            <div
                              className={`icon-container w-20 mr-6 md:mr-0 md:mb-10 ${styles.iconContainer} self-center`}
                            >
                              <img src={Money} alt="" className="w-full" />
                            </div>
                            <h3 className="self-center whitespace-no-wrap">
                              月供大約
                            </h3>
                          </div>
                          <h3>HK$6,500</h3>
                        </>
                      ) : (
                        <>
                          <div className="flex md:flex-col">
                            <div
                              className={`icon-container w-20 mr-6 md:mr-0 md:mb-10 ${styles.iconContainer} self-center`}
                            >
                              <img src={Money} alt="" className="w-full" />
                            </div>
                            <h3 className="self-center whitespace-no-wrap">
                              The monthly payment will be
                            </h3>
                          </div>
                          <h3>around $6,500.</h3>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 md:w-full pl-20 md:pl-0 md:mt-10">
                <p className="large-text mb-32 md:mb-16">
                  {isChinese
                    ? "若果以60個月供款和10%首期的租賃方式選購一部BMW 218iA Gran Tourer:"
                    : "They have decided on a The BMW 218iA Gran Tourer."}
                </p>
                <p className="large-text light-font">
                  {isChinese
                    ? ""
                    : "At first they plan to go with Leasing option with a period of 60 months and 10% Down Payment,"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionEight({ data }) {
  return (
    <section>
      <div className="image-container">
        <Img fluid={data.articleimg4.childImageSharp.fluid} />
      </div>
    </section>
  )
}

function SectionNine({ data, isChinese }) {
  return (
    <section className="py-40 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="flex">
              <div className="w-1/2 md:w-full">
                <h3 className="text-5xl md:text-4xl bold-font mb-32 md:mb-10">
                  {isChinese
                    ? "而選擇同樣年期和首期的BMW Circle:"
                    : "On the other hand, if they choose BMW Circle under the same period of 60 months and 10 % Down Payment"}
                </h3>
              </div>
            </div>
            <div className="flex md:flex-col">
              <div className="w-1/2 md:w-full pr-20 md:pr-0">
                <div className="flex justify-end">
                  <hr className="w-64 md:w-full h-1 bg-black mb-32 md:hidden" />
                </div>
                <div className="flex justify-end">
                  <div className="md:w-full mb-32 text-right md:text-center md:mt-20 md:mb-10">
                    <div className="headline md:text-4xl">
                      {isChinese ? (
                        <>
                          <div className="flex md:flex-col">
                            <div
                              className={`icon-container w-20 mr-6 md:mr-0 md:mb-10 ${styles.iconContainer} self-center`}
                            >
                              <img src={Money} alt="" className="w-full" />
                            </div>
                            <h3 className="self-center whitespace-no-wrap">
                              月供只需大約
                            </h3>
                          </div>
                          <h3 className="md:text-center">HK$4,000</h3>
                        </>
                      ) : (
                        <>
                          <div className="flex md:flex-col">
                            <div
                              className={`icon-container w-20 mr-6 md:mr-0 md:mb-10 ${styles.iconContainer} self-center`}
                            >
                              <img src={Money} alt="" className="w-full" />
                            </div>
                            <h3 className="self-center whitespace-no-wrap">
                              The monthly payment is
                            </h3>
                          </div>
                          <h3>around $4,000.</h3>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 md:w-full pl-20 md:pl-0 md:mt-10">
                <p className="large-text light-font">
                  {isChinese
                    ? "家庭便可以更加輕鬆應付日常開支，甚至為小朋友計劃更好的將來。"
                    : "The money saved from car payments can again be reallocated, perhaps to prepare for their child’s future."}
                </p>
                <div className="btn-container mt-16">
                  <a
                    href={
                      isChinese
                        ? "https://connect.bmwhk.com/tc/?utm_medium=website&utm_source=awb_site&utm_campaign=AlongWithBMW2020&utm_content=standard&lang=tc"
                        : "https://connect.bmwhk.com/en/?utm_medium=website&utm_source=awb_site&utm_campaign=AlongWithBMW2020&utm_content=standard&lang=en"
                    }
                    target="_blank"
                    className="px-8 py-4 border border-solid border-black inline-block hover:bg-black hover:text-white duration-300 transition-all ease-out"
                  >
                    {isChinese
                      ? "了解更多有關車款優惠"
                      : "Learn More About Car offers"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionTen({ data }) {
  return (
    <section>
      <div className="image-container">
        <Img fluid={data.articleimg5.childImageSharp.fluid} />
      </div>
    </section>
  )
}

function SectionEleven({ isChinese }) {
  return (
    <section className="py-40 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="flex">
              <div className="w-1/2 md:w-full">
                <h3 className="text-5xl md:text-4xl bold-font mb-32 md:mb-20">
                  {isChinese
                    ? "那到底BMW Circle有什麼特別? "
                    : "What’s so special about BMW Circle?"}
                </h3>
              </div>
            </div>
            <div className="flex md:flex-col">
              <div className="w-1/2 md:w-full pr-32 md:pr-0 md:mb-20">
                <div className="flex justify-center flex-wrap">
                  <div className="w-7/12 md:w-64">
                    <div className="image-container mb-20">
                      <img src={Loan} alt="" />
                    </div>
                  </div>
                  <div className="w-full">
                    <p className="large-text light-font text-center">
                      {isChinese
                        ? "供款期內，只需償還部分貸款額，令每月支付更低月供。"
                        : "Low installments over the terms"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-1/2 md:w-full pl-32 md:pl-0">
                <div className="flex justify-center flex-wrap">
                  <div className="w-7/12 md:w-64">
                    <div className="image-container mb-20">
                      <img src={List} alt="" />
                    </div>
                  </div>
                  <div className="w-full">
                    <p className="large-text light-font text-center mb-16">
                      {isChinese
                        ? "合約期滿時有多項選擇"
                        : "Flexible end of term options"}
                    </p>
                    <div className="flex mb-8">
                      <div className="w-16 mr-6 self-center">
                        <img src={One} alt="" />
                      </div>
                      <p className="text-4xl light-font self-center">
                        {isChinese
                          ? "Trade-in換新車"
                          : "Trade in your car for a brand new BMW"}
                      </p>
                    </div>
                    <div className="flex mb-8">
                      <div className="w-16 mr-6 self-center">
                        <img src={Two} alt="" />
                      </div>
                      <p className="text-4xl light-font self-center">
                        {isChinese
                          ? "申請延伸尾期供款"
                          : "Refinance the outstanding sum"}
                      </p>
                    </div>
                    <div className="flex">
                      <div className="w-16 mr-6 self-center">
                        <img src={Three} alt="" />
                      </div>
                      <p className="text-4xl light-font self-center">
                        {isChinese
                          ? "直接支付剩餘款項完成供款"
                          : "Simply settle the lump sum"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionTwelve({ data, isChinese }) {
  return (
    <section className="py-40 md:py-20 bg-black text-white">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="flex">
              <div className="w-1/2 md:w-full pr-20">
                <h3 className="text-5xl md:text-4xl bold-font mb-32 md:mb-10">
                  {isChinese
                    ? "緊貼BMW資訊，為現在及未來做好規劃，開拓寬闊通道。更多資訊陸續為您送上！"
                    : "Empower yourself with information. Stay tuned to our latest updates about Along with BMW and learn more about our BMW experience."}
                </h3>
              </div>
            </div>
            <div className="flex justify-end">
              <div className="w-1/2 md:w-full">
                <div className="flex">
                  <hr className="w-64 h-1 bg-black mb-32" />
                </div>
                <p className="text-3xl">
                  {isChinese
                    ? "忠告: 借錢梗要還，咪俾錢中介。"
                    : "Warning: You have to repay your loans. Don’t pay any intermediaries"}
                </p>
                <p className="text-3xl">
                  {isChinese
                    ? "BMW Financial Services Hong Kong Limited"
                    : "BMW Financial Services HK Ltd."}
                </p>
                <p className="text-3xl">
                  {isChinese
                    ? "放債人牌照號碼︰0289/2020"
                    : "Money Lender License No. 0289/2020"}
                </p>
                <p className="text-3xl">
                  {isChinese
                    ? "投訴熱線：+852 2598 1009"
                    : "Complaint Hotline: 25981009"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
